import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { getFlex, palette, singleSlide } from "../../styles/styles";
import Image from "../../ImageQuerys/CarouselImages";

const LocalCarousel = (props) => {
  const { className, info, nameSpace, isFromDigital } = props;
  const { t } = useTranslation(nameSpace);

  const [clickToRight, setClickToRight] = useState(3);
  const [active, setActive] = useState(4);
  const [clickToLeft, setClickToLeft] = useState(5);
  const [data, setData] = useState(info.concat(info).concat(info));
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");

  const onClick = (index, title, desc) => {
    const direction =
      index === clickToRight
        ? "right"
        : index === clickToLeft
        ? "left"
        : "none";
    setTitle(title);
    setDesc(desc);
    move(direction);
  };

  const getNextClass = (direction, current) => {
    if (direction === "right") {
      if (current.includes("sing0")) return ["sing0", "sing1"];
      if (current.includes("sing1")) return ["sing1", "sing2"];
      if (current.includes("sing2")) return ["sing2", "sing3"];
      if (current.includes("sing3")) return ["sing3", "sing4"];
      if (current.includes("sing4")) return ["sing4", "sing5"];
      if (current.includes("sing5")) return ["sing5", "sing6"];
      if (current.includes("sing6")) return ["sing6", "sing7"];
      if (current.includes("sing7")) return ["sing7", "sing8"];
      if (current.includes("sing8")) return ["sing8", "sing0"];
    } else {
      if (current.includes("sing0")) return ["sing0", "sing8"];
      if (current.includes("sing1")) return ["sing1", "sing0"];
      if (current.includes("sing2")) return ["sing2", "sing1"];
      if (current.includes("sing3")) return ["sing3", "sing2"];
      if (current.includes("sing4")) return ["sing4", "sing3"];
      if (current.includes("sing5")) return ["sing5", "sing4"];
      if (current.includes("sing6")) return ["sing6", "sing5"];
      if (current.includes("sing7")) return ["sing7", "sing6"];
      if (current.includes("sing8")) return ["sing8", "sing7"];
    }
  };

  const move = (direction) => {
    // ALL RIGHT
    if (direction === "right") {
      for (let i = 0; i <= 8; i++) {
        const classNames = getNextClass(
          "right",
          document.getElementById(i).classList.value
        );
        document.getElementById(i).classList.toggle(classNames[0]);
        document.getElementById(i).classList.toggle(classNames[1]);
      }
      document.getElementById(active).classList.toggle(`active`);
      document
        .getElementById(active ? active - 1 : 8)
        .classList.toggle(`active`);
      setActive((prevState) => (prevState ? prevState - 1 : 8));
      setClickToRight((prevState) => (prevState ? prevState - 1 : 8));
      setClickToLeft((prevState) => (prevState ? prevState - 1 : 8));

      // All LEFT
    } else if (direction === "left") {
      for (let i = 0; i <= 8; i++) {
        const classNames = getNextClass(
          "left",
          document.getElementById(i).classList.value
        );
        document.getElementById(i).classList.toggle(classNames[0]);
        document.getElementById(i).classList.toggle(classNames[1]);
      }
      document.getElementById(active).classList.toggle(`active`);
      document
        .getElementById(active === 8 ? 0 : active + 1)
        .classList.toggle(`active`);
      setActive((prevState) => (prevState === 8 ? 0 : prevState + 1));
      setClickToRight((prevState) => (prevState === 8 ? 0 : prevState + 1));
      setClickToLeft((prevState) => (prevState === 8 ? 0 : prevState + 1));
    }
  };

  useEffect(() => {
    document.getElementById(active).classList.toggle(`active`);
  }, []);

  useEffect(() => {
    setTitle(t(data[1].title));
    setDesc(t(data[1].desc));
  }, [data]);

  return (
    <section className={className}>
      <div className="info">
        {isFromDigital ? (
          <div className="inDigital">
            <p className="red">{t("carouselRed")}</p>
            <h2>{title}</h2>
          </div>
        ) : (
          <h2>{title}</h2>
        )}
        <p>{desc}</p>
      </div>
      <div id="slides" className="slides">
        {data.map((item, index) => {
          return (
            <div
              className={`single sing${index}`}
              id={`${index}`}
              onClick={() => onClick(index, t(item.title), t(item.desc))}
            >
              <Image className="img" imageName={item.img} alt="" />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default styled(LocalCarousel)`
  padding: 8% 10%;
  height: 95rem;
  /*@media (max-height: 699px) {
    height: 100vh;
  }
  @media (min-height: 700px) {
    height: 100rem;
  }*/
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  justify-items: center;
  grid-row-gap: 4rem;
  @media (min-width: 1440px) {
    height: 100rem;
  }
  @media (max-width: 1200px) {
    height: 90rem;
  }
  @media (max-width: 1024px) {
    height: 95rem;
  }
  @media (max-width: 900px) {
    height: 80rem;
  }
  @media (max-width: 768px) {
    height: 85rem;
    grid-template-rows: 1.3fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-rows: 1.5fr 1fr;
    padding: 8% 5%;
  }
  .info {
    ${getFlex("column", "ct", "ct")};
    grid-row-gap: 2rem;
    height: 100%;
    .inDigital {
      ${getFlex("column", "ct", "ct")};
    }
    .red {
      font-size: 2rem;
      color: ${palette.red};
      margin: auto;
    }
    h2 {
      font-size: 4.8rem;
      width: 80%;
      @media (max-width: 900px) {
        width: 85%;
      }
      @media (max-width: 768px) {
        width: 90%;
      }
      @media (max-width: 600px) {
        width: 95%;
      }
    }
    p {
      font-size: 1.6rem;
      width: 60%;
      text-align: center;
      font-family: SofiaProLightAz, sans-serif;
      @media (max-width: 900px) {
        width: 70%;
      }
      @media (max-width: 768px) {
        width: 80%;
      }
      @media (max-width: 600px) {
        width: 90%;
      }
    }
  }

  .slides {
    ${getFlex("row", "se", "ed")};
    overflow: hidden;
    height: 100%;
    width: 100%;
    // max-width: 1900px;
    min-height: fit-content;
    position: relative;
    @media (min-width: 1840px) {
      min-height: 320px;
    }
    .single {
      ${singleSlide};
      .img {
        max-width: 400px;
      }
    }
    .sing0 {
      left: -100%;
      display: none !important;
    }
    .sing1 {
      left: calc(-100% / 3 + -100% / 3);
    }
    .sing2 {
      left: calc(-100% / 3);
    }
    .sing3 {
      left: 0;
    }
    .sing4 {
      left: calc(100% / 3);
    }
    .sing5 {
      left: calc(100% / 3 + 100% / 3);
    }
    .sing6 {
      left: calc(100%);
    }
    .sing7 {
      left: calc(100% + 100% / 3);
    }
    .sing8 {
      left: calc(100% + 100% / 3 + 100% / 3);
      display: none !important;
    }
    .active {
      opacity: 1;
      .img {
        width: 100%;
        transition: all 1s ease;
        max-width: 460px !important;
      }
    }
  }
`;

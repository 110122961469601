import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Image = (props) => {
  const { className, imageName, onClick } = props;

  const data = useStaticQuery(graphql`
    query CarouselImages {
      allFile(
        filter: {
          relativePath: {}
          relativeDirectory: { eq: "carousel" }
        }
      ) {
        edges {
          node {
            id
            base
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  return data.allFile.edges.map(({ node }) => {
    if (node.base === imageName) {
      const image = getImage(node);
      return <GatsbyImage onClick={onClick} className={className} image={image} alt="image" />;
    }
  });
};

export default Image;

import React from "react";
import styled from "styled-components";
import Image from "../../ImageQuerys/LogoImages";
import { getFlex } from "../../styles/styles";

const Partners = (props) => {
  const { className, logos } = props;
  return (
    <section className={className}>
      {logos.map((l) => (
        <Image className="partner" imageName={l} />
      ))}
    </section>
  );
};

export default styled(Partners)`
  padding: 4% 10%;
  height: 22vh;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 4rem;
  align-items: center;
  justify-items: center;
  @media(max-height: 899px){
    height: 22vh;
  }
  @media(min-height: 900px){
    height: 22rem;
  }
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 50%;
    grid-row-gap: 4rem;
    grid-column-gap: 1rem;
    align-items: center;
    justify-items: center;
    .partner:nth-child(-1n + 3) {
      grid-column: span 4;
    }
    .partner:nth-last-child(2) {
      grid-row-start: 2;
      grid-column: 3 / span 4;
    }
    .partner:nth-last-child(1) {
      grid-row-start: 2;
      grid-column: 7 / span 4;
    }
  }
  @media (max-width: 600px) {
    grid-row-gap: 2rem;
  }
  .partner {
    @media (max-width: 768px) {
      width: 70% !important;
    }
  }
`;

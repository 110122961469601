import React from "react";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";

const SimpleParagraph = (props) => {
  const { className, nameSpace } = props;
  const { t } = useTranslation(nameSpace);

  return (
    <section className={className}>
      <h2>{t("simpleTitle")}</h2>
      <p>{t("simpleDesc")}</p>
    </section>
  );
};

export default styled(SimpleParagraph)`
  padding: 8% 16%;
  display: grid;
  grid-template-rows: auto;
  align-items: center;
  justify-items: center;
  height: fit-content;
  grid-row-gap: 2rem;
  @media (max-width: 600px) {
    padding: 10% 8%;
  }
  h2 {
    font-size: 4.8rem;
    text-align: center;
  }
  p {
    font-size: 2rem;
    font-family: SofiaProLightAz, sans-serif;
    text-align: center;
    width: 80%;
  }
`;
